import { Routes } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { authGuard } from "./guards/auth.guard";
import { dbAuthGuard } from "./guards/db.auth.guard";
import { AllNotesComponent } from "./components/all-notes/all-notes.component";

export const routes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/dashboard/dashboard.component").then(
        (c) => c.DashboardComponent
      ),
    canActivate: [authGuard],
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./components/all-notes/all-notes.component").then(
            (c) => c.AllNotesComponent
          ),
      },
      {
        path: ":tagName",
        loadComponent: () =>
          import("./components/all-notes/all-notes.component").then(
            (c) => c.AllNotesComponent
          ),
      },
      {
        path: "note/:id",
        loadComponent: () =>
          import("./components/note-editor/note-editor.component").then(
            (c) => c.NoteEditorComponent
          ),
        canActivate: [dbAuthGuard],
      },
      // component: NoteEditorComponent
    ],
  },
];
