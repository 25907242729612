import { ApplicationConfig, provideZoneChangeDetection, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAuth } from 'angular-auth-oidc-client';
import { buildAngularAuthConfig, UserScope } from '@logto/js';
import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideAuth({
      config: buildAngularAuthConfig({
        endpoint: 'https://auth-logto.dpkkph.easypanel.host/',
        appId: 'uimm7mfoh31kvp5xdj8s5',
        redirectUri: 'https://note.miroslavpetro.com',
        postLogoutRedirectUri: 'https://note.miroslavpetro.com',
        resource: 'http://localhost:3000',
        scopes: [
          // UserScope.Email,
          'read', 'write'
        ]
      }),
    }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }),
  ],
};
