import camelcaseKeys from 'camelcase-keys';
import { QueryKey, TokenGrantType, ContentType } from '../consts/index.js';
const fetchTokenByAuthorizationCode = async ({
  clientId,
  tokenEndpoint,
  redirectUri,
  codeVerifier,
  code,
  resource
}, requester) => {
  const parameters = new URLSearchParams();
  parameters.append(QueryKey.ClientId, clientId);
  parameters.append(QueryKey.Code, code);
  parameters.append(QueryKey.CodeVerifier, codeVerifier);
  parameters.append(QueryKey.RedirectUri, redirectUri);
  parameters.append(QueryKey.GrantType, TokenGrantType.AuthorizationCode);
  if (resource) {
    parameters.append(QueryKey.Resource, resource);
  }
  const snakeCaseCodeTokenResponse = await requester(tokenEndpoint, {
    method: 'POST',
    headers: ContentType.formUrlEncoded,
    body: parameters.toString()
  });
  return camelcaseKeys(snakeCaseCodeTokenResponse);
};
/**
 * Fetch access token by refresh token using the token endpoint and `refresh_token` grant type.
 * @param params The parameters for fetching access token.
 * @param requester The requester for sending HTTP request.
 * @returns A Promise that resolves to the access token response.
 */
const fetchTokenByRefreshToken = async (params, requester) => {
  const {
    clientId,
    tokenEndpoint,
    refreshToken,
    resource,
    organizationId,
    scopes
  } = params;
  const parameters = new URLSearchParams();
  parameters.append(QueryKey.ClientId, clientId);
  parameters.append(QueryKey.RefreshToken, refreshToken);
  parameters.append(QueryKey.GrantType, TokenGrantType.RefreshToken);
  if (resource) {
    parameters.append(QueryKey.Resource, resource);
  }
  if (organizationId) {
    parameters.append(QueryKey.OrganizationId, organizationId);
  }
  if (scopes?.length) {
    parameters.append(QueryKey.Scope, scopes.join(' '));
  }
  const snakeCaseRefreshTokenTokenResponse = await requester(tokenEndpoint, {
    method: 'POST',
    headers: ContentType.formUrlEncoded,
    body: parameters.toString()
  });
  return camelcaseKeys(snakeCaseRefreshTokenTokenResponse);
};
export { fetchTokenByAuthorizationCode, fetchTokenByRefreshToken };