import '../consts/index.js';
import { ReservedScope, UserScope } from '../consts/openid.js';

/**
 * @param originalScopes
 * @return scopes should contain all default scopes (`openid`, `offline_access` and `profile`)
 */
const withReservedScopes = originalScopes => {
  const reservedScopes = Object.values(ReservedScope);
  const uniqueScopes = new Set([...reservedScopes, UserScope.Profile, ...(originalScopes ?? [])]);
  return Array.from(uniqueScopes).join(' ');
};
/**
 * Alias of {@link withReservedScopes}.
 *
 * @deprecated Use {@link withReservedScopes} instead.
 */
const withDefaultScopes = withReservedScopes;
export { withDefaultScopes, withReservedScopes };