import { Prompt } from '../consts/index.js';
import { withReservedScopes } from './scopes.js';

/**
 * A helper function to build the OpenID Connect configuration for `angular-auth-oidc-client`
 * using a Logto-friendly way.
 *
 * @example
 * ```ts
 * // A minimal example
 * import { buildAngularAuthConfig } from '@logto/js';
 * import { provideAuth } from 'angular-auth-oidc-client';
 *
 * provideAuth({
 *   config: buildAngularAuthConfig({
 *     endpoint: '<your-logto-endpoint>',
 *     appId: '<your-app-id>',
 *     redirectUri: '<your-app-redirect-uri>',
 *   }),
 * });
 * ```
 *
 * @param logtoConfig The Logto configuration object for Angular apps.
 * @returns The OpenID Connect configuration for `angular-auth-oidc-client`.
 * @see {@link https://angular-auth-oidc-client.com/ | angular-auth-oidc-client} to learn more
 * about how to use the library.
 */
const buildAngularAuthConfig = logtoConfig => {
  const {
    endpoint,
    appId: clientId,
    scopes,
    resource,
    redirectUri: redirectUrl,
    postLogoutRedirectUri,
    prompt = Prompt.Consent,
    includeReservedScopes = true
  } = logtoConfig;
  const scope = includeReservedScopes ? withReservedScopes(scopes) : scopes?.join(' ');
  const customParameters = resource ? {
    resource
  } : undefined;
  return {
    authority: new URL('/oidc', endpoint).href,
    redirectUrl,
    postLogoutRedirectUri,
    clientId,
    scope,
    responseType: 'code',
    autoUserInfo: !resource,
    renewUserInfoAfterTokenRenew: !resource,
    silentRenew: true,
    useRefreshToken: true,
    customParamsAuthRequest: {
      prompt: Array.isArray(prompt) ? prompt.join(' ') : prompt,
      ...customParameters
    },
    customParamsCodeRequest: {
      ...customParameters
    },
    customParamsRefreshTokenRequest: {
      ...customParameters
    }
  };
};
export { buildAngularAuthConfig };