import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterOutlet } from "@angular/router";
import { id, init, tx, txInit, User } from "@instantdb/core";
import { NgxTiptapModule } from "ngx-tiptap";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { CommonModule } from "@angular/common";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { UserInfoResponse } from "@logto/js";
import { DBService } from "./services/db.service";
import { PromptUpdateService } from "./services/update.service";
@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, CommonModule, FormsModule, NgxTiptapModule],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnDestroy, OnInit {
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public db: DBService,
    private _reloadService: PromptUpdateService
  ) {}

  // signIn() {
  //   this.oidcSecurityService.authorize();
  // }

  // signOut() {
  //   this.db.logout();

  //   this.oidcSecurityService.logoff().subscribe((result) => {
  //     console.log('app sign-out', result);
  //   });
  // }

  // isAuthenticated = false;
  // userData?: UserInfoResponse;
  // idToken?: string;
  // accessToken?: string;

  ngOnInit() {
    // this.oidcSecurityService.getConfiguration().subscribe((result) => {
    //   console.log('app configuration', result);
    // });
    // this.oidcSecurityService
    //   .checkAuth()
    //   .subscribe(({ isAuthenticated, userData, idToken, accessToken }) => {
    //     console.log('app authenticated', isAuthenticated);
    //     this.isAuthenticated = isAuthenticated;
    //   })
    //     this.userData = userData;
    //     console.log('app userData', userData);
    //     this.idToken = idToken;
    //     console.log('app idToken', idToken);
    //     this.accessToken = accessToken;
    //     console.log('app accessToken', accessToken);
    //   });
  }
  // title = 'client';
  // private APP_ID = '0f194479-483f-4ba6-a794-1d622c04510f';
  // public todos: Todo[] = [];
  // public code = '';
  // public db = init<Schema>({ appId: this.APP_ID });
  // public editor = new Editor({
  //   extensions: [StarterKit],
  // });

  // value = '<p>Hello, Tiptap!</p>';
  // user: User | null = null;
  // constructor() {
  //   this.db.subscribeQuery({ todo: {} }, (resp) => {
  //     if (resp.error) {
  //       console.log(resp.error);
  //       return;
  //     }
  //     if (resp.data) {
  //       this.todos = resp.data.todo;
  //     }
  //     this.db.subscribeAuth((auth) => {
  //       if (auth.error) {
  //         console.log(auth.error);
  //         this.user = null;
  //         return;
  //       }
  //       if (auth.user) {
  //         console.log(auth.user);
  //         this.user = auth.user;
  //       } else {
  //         console.log('Loading');
  //         this.user = null;
  //       }
  //     });
  //   });
  // }
  // public addNote() {
  //   this.db.transact(
  //     tx['todo'][id()].update({
  //       text: this.value,
  //       done: false,
  //       createdAt: Date.now(),
  //       createdBy: this.user?.id || '',
  //     })
  //   );
  // }

  // public remove(id: string) {
  //   this.db.transact(tx['todo'][id].delete());
  // }

  // public sendCode() {
  //   this.db.auth
  //     .sendMagicCode({ email: 'miroslav.petro016@gmail.com' })
  //     .then((value) => {
  //       console.log('Sent: ', value);
  //     });
  // }

  // public verifyCode() {
  //   this.db.auth
  //     .signInWithMagicCode({
  //       email: 'miroslav.petro016@gmail.com',
  //       code: this.code,
  //     })
  //     .then((value) => {
  //       console.log('Verified: ', value);
  //     });
  // }

  // public signOut() {
  //   this.db.auth.signOut().then((value) => {
  //     console.log('SignOut: ', value);
  //   });
  // }

  ngOnDestroy(): void {
    // this.editor.destroy();
  }
}
// interface Todo {
//   id: string;
//   text: string;
//   done: boolean;
//   createdAt: number;
//   createdBy: string;
// }

// type Schema = {
//   todo: Todo;
// };
