import { isArbitraryObject } from './arbitrary-object.js';
const logtoErrorCodes = Object.freeze({
  'id_token.invalid_iat': 'Invalid issued at time in the ID token',
  'id_token.invalid_token': 'Invalid ID token',
  'callback_uri_verification.redirect_uri_mismatched': 'The callback URI mismatches the redirect URI.',
  'callback_uri_verification.error_found': 'Error found in the callback URI',
  'callback_uri_verification.missing_state': 'Missing state in the callback URI',
  'callback_uri_verification.state_mismatched': 'State mismatched in the callback URI',
  'callback_uri_verification.missing_code': 'Missing code in the callback URI',
  crypto_subtle_unavailable: 'Crypto.subtle is unavailable in insecure contexts (non-HTTPS).',
  unexpected_response_error: 'Unexpected response error from the server.'
});
class LogtoError extends Error {
  constructor(code, data) {
    super(logtoErrorCodes[code]);
    this.code = code;
    this.data = data;
    this.name = 'LogtoError';
  }
}
const isLogtoRequestError = data => {
  if (!isArbitraryObject(data)) {
    return false;
  }
  return data instanceof Error && data.name === 'LogtoRequestError';
};
const isLogtoRequestErrorJson = data => {
  if (!isArbitraryObject(data)) {
    return false;
  }
  return typeof data.code === 'string' && typeof data.message === 'string';
};
class LogtoRequestError extends Error {
  constructor(code, message, /** The original response object from the server. */
  cause) {
    super(message);
    this.code = code;
    this.cause = cause;
    this.name = 'LogtoRequestError';
  }
}
class OidcError {
  constructor(error, errorDescription) {
    this.error = error;
    this.errorDescription = errorDescription;
    this.name = 'OidcError';
  }
}
export { LogtoError, LogtoRequestError, OidcError, isLogtoRequestError, isLogtoRequestErrorJson };