<!-- <button class="btn btn-accent" (click)="addNote()">Add</button>
<button class="btn btn-accent" (click)="sendCode()">Code</button>
<input
  type="text"
  placeholder="Code"
  class="input w-full max-w-xs"
  [(ngModel)]="code"
/>
<button class="btn btn-accent" (click)="verifyCode()">Verify code</button>
<button class="btn btn-accent" (click)="signOut()">SignOut</button>
<tiptap-editor [editor]="editor" [(ngModel)]="value"></tiptap-editor>
{{ user | json }}
@for (todo of todos; track $index) {
<div class="card bg-neutral text-neutral-content w-96">
  <div class="card-body items-center text-center">
    <h2 class="card-title">Cookies!</h2>
    <p>{{ todo.text }}</p>
    <div class="card-actions justify-end">
      <button class="btn btn-primary" (click)="remove(todo.id)">Remove</button>
    </div>
  </div>
</div>

} -->
<router-outlet></router-outlet>

<!-- 
<button (click)="signIn()">Sign in</button>
<br />
<button (click)="signOut()">Sign out</button> -->
