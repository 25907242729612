import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { DBService } from "../services/db.service";
import { OidcSecurityService } from "angular-auth-oidc-client";

export const dbAuthGuard: CanActivateFn = (route, state) => {
  const db = inject(DBService);
  const oidcSecurityService = inject(OidcSecurityService);

  return db.isDbAuth().then((auth) => {
    if (!auth) {
      oidcSecurityService
        .checkAuth()
        .subscribe(({ isAuthenticated, userData, idToken, accessToken }) => {
          if (isAuthenticated && accessToken) {
            // db.authUser(accessToken).subscribe((res) => {
            //   return res;
            // });
          }
        });
    }
    return true;
  });
};
