import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { DBService } from "../services/db.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { firstValueFrom, map, Observable } from "rxjs";

export const authGuard: CanActivateFn = async (route, state) => {
  const db = inject(DBService);
  const oidcSecurityService = inject(OidcSecurityService);
  const loginResponse = await firstValueFrom(oidcSecurityService.checkAuth());

  if (!loginResponse.isAuthenticated) {
    oidcSecurityService.authorize();
  }
  if (await db.isDbAuth()) {
    return true;
  }
  return (await db.authUser(loginResponse.accessToken)) || false;
};
